<template>
  <el-main v-if="info">
    <!-- <div class="header">
      <div>
        <el-button type="primary" v-show="info.shopCard">VIP会员</el-button>
        <el-button type="text" v-show="info.shopCard" @click="showRecharge = !0">送余额</el-button>
        <el-button type="text" @click="showCoupon = !0">赠优惠券</el-button>
        <el-button type="text" @click="frozen">{{ info.isblack ? '恢复' : '冻结' }}</el-button>
      </div>
    </div> -->
    <div class="group" v-if="cluster.length">
      <div class="title">1、用户分群</div>
      <div class="content">
        <ul>
          <li :style="{ background: item.back, color: item.color, borderColor: item.color }" v-for="(item, index) in cluster" :key="index">{{ item.title }}</li>
        </ul>
        <div>
          <el-button type="primary" size="small" @click="frozen">{{ info.isblack ? '恢复' : '冻结' }}</el-button>
        </div>
      </div>
    </div>
    <div class="user-info">
      <div class="title">{{ cluster.length ? 2 : 1 }}、用户信息</div>
      <div class="content padding between">
        <div class="left">
          <p>
            <el-image :src="info.avatar"></el-image>
            {{ info.nickname || '--' }}
            <el-tag v-show="info.external_contact" size="small" style="margin-left: 5px" type="success" effect="plain">企微好友</el-tag>
          </p>
          <p>用户姓名：</p>
          <p>手机号码：{{ info.mobile ? info.mobile : '--' }}</p>
          <p style="display: flex; align-items: center">
            用户来源：
            <el-image style="display: flex" :src="info.comefrom"></el-image>
          </p>
          <p v-show="info.external_contact && info.external_contact_tag.length">
            企微标签：
            <el-tag style="margin-right: 10px" v-for="item in info.external_contact_tag" :key="item" size="small" type="info">{{ item }}</el-tag>
          </p>
          <p>注册时间：{{ getDateformat(info.create_time) }}</p>
        </div>
        <div class="right">
          <!-- <el-button type="primary" size="small">联系客户</el-button> -->
          <!-- <el-button type="primary" size="small">编辑信息</el-button> -->
        </div>
      </div>
    </div>
    <div class="card-info">
      <div class="title">
        <span>{{ cluster.length ? 3 : 2 }}、会员信息</span>
      </div>
      <div class="content padding">
        <p>用户身份：{{ info.shopCard ? '会员' : '非会员' }}</p>
        <p>会员卡号：{{ info.shopCard ? info.shopCard.sn : '--' }}</p>
        <p>会员等级：{{ info.level ? info.level.title : '--' }}</p>
        <p>
          会员卡状态：
          <span v-if="info.shopCard">{{ info.shopCard.status == 1 ? '正常' : info.shopCard.status == -1 ? '删除' : info.shopCard.status == 0 ? '待支付' : '--' }}</span>
          <span v-else>--</span>
        </p>
        <p v-show="act_reward.length">
          会员权益：
          <span style="margin-right: 10px" v-for="(item, index) in act_reward" :key="index">{{ index + 1 + '.' + item }}</span>
        </p>
      </div>
    </div>
    <div class="data">
      <div class="title">
        <span>{{ cluster.length ? 4 : 3 }}、资产信息</span>
        <div>
          <el-button type="text" size="small" @click="showIntegral = !0">查看积分</el-button>
          <el-button type="text" size="small" @click="showMyCoupon = !0">查看优惠券</el-button>
          <el-button type="text" size="small" @click="showGrowLog = !0">查看成长值</el-button>
          <el-button type="text" size="small" @click="showBalance = !0">查看余额</el-button>
        </div>
      </div>
      <div class="content">
        <div class="item">
          <div class="left">
            <p>{{ statistics[0].balance }}</p>
            <p>会员卡金额</p>
          </div>
          <el-button @click="showRecharge = !0" type="primary" size="small">送余额</el-button>
        </div>
        <div class="item">
          <div class="left">
            <p>{{ statistics[0].coupons_total }}</p>
            <p>优惠券（张）</p>
          </div>
          <el-button @click="showCoupon = !0" type="primary" size="small">赠优惠券</el-button>
        </div>
        <div class="item">
          <div class="left">
            <p>{{ statistics[0].integral }}</p>
            <p>积分</p>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <p>{{ statistics[0].grow_value }}</p>
            <p>成长值</p>
          </div>
        </div>
      </div>
    </div>
    <div class="address">
      <div class="title">{{ cluster.length ? 5 : 4 }}、收货地址</div>
      <div class="content">
        <el-table :data="info.address" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <el-table-column prop="truename" label="姓名" align="center"></el-table-column>
          <el-table-column prop="mobile_phone" label="手机号码" align="center"></el-table-column>
          <el-table-column prop="combine_detail" label="省市区域" align="center"></el-table-column>
          <el-table-column prop="address" label="详细地址" align="center"></el-table-column>
          <el-table-column prop="is_default" label="是否默认" align="center">
            <template slot-scope="scope">
              {{ scope.row.is_default ? '是' : '否' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="address">
      <div class="title">
        <span>{{ cluster.length ? 6 : 5 }}、消费信息</span>
        <router-link :to="{ path: '/order/orderList', query: { user_ids: id } }" target="_blank">
          <el-button type="text" size="small">查看全部订单</el-button>
        </router-link>
      </div>
      <div class="content">
        <el-table :data="consumption" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <el-table-column prop="xf_time" label="最近下单时间" align="center"></el-table-column>
          <el-table-column prop="cost_price" label="客单价" align="center"></el-table-column>
          <el-table-column prop="xf_num" label="累计订单数" align="center"></el-table-column>
          <el-table-column prop="refund_amount" label="累计退款金额" align="center"></el-table-column>
          <el-table-column prop="refund_num" label="累计退款单数" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="address">
      <div class="title">
        <span>{{ cluster.length ? 7 : 6 }}、商品偏好</span>
        <router-link :to="{ path: '/user/userLog', query: { user_id: id } }" target="_blank">
          <el-button type="text" size="small">查看用户日志</el-button>
        </router-link>
      </div>
      <div class="content">
        <el-table :data="preference" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <el-table-column prop="deep" label="访问深度" align="center"></el-table-column>
          <el-table-column prop="goods_classify" label="最后访问分类" align="center"></el-table-column>
          <el-table-column prop="lastgoods" label="最后浏览商品" align="center"></el-table-column>
          <el-table-column prop="last_add_cart_goods" label="最后加入购物车商品" align="center"></el-table-column>
          <el-table-column prop="last_order_submit" label="最后提交订单支付商品" align="center"></el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog title="优惠券明细" :visible.sync="showMyCoupon" width="35%" center>
      <el-table :data="myCoupons" height="400px" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="create_time" label="领取时间" align="center">
          <template slot-scope="scope">
            {{ getDateformat(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="优惠券名称" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            {{ scope.row.status == 1 ? '未使用' : scope.row.status == 2 ? '已使用' : '已过期' }}
          </template>
        </el-table-column>
        <el-table-column prop="source" label="来源" align="center">
          <template slot-scope="scope">
            {{ scope.row.source == 1 ? '领取' : scope.row.source == 2 ? '后台赠送' : '开卡赠送' }}
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange($event, 1)"
          @current-change="handleCurrentChange($event, 1)"
          :current-page="myCouponsParameter.page"
          :page-size="myCouponsParameter.rows"
          :total="myCouponsParameter.total_number"
        ></el-pagination>
      </div>
    </el-dialog>
    <el-dialog title="积分明细" :visible.sync="showIntegral" width="35%" center>
      <el-table :data="myCoupons" height="400px" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="create_time" label="时间" align="center">
          <template slot-scope="scope">
            {{ getDateformat(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="用户行为" align="center">
          <template slot-scope="scope">
            {{ scope.row.type == 1 ? '签到送积分' : scope.row.type == 2 ? '购买商品送积分' : scope.row.type == 3 ? '取消兑换积分商品返还' : '兑换积分商品' }}
          </template>
        </el-table-column>
        <el-table-column prop="integral" label="积分" align="center">
          <template slot-scope="scope">
            {{ scope.row.type == 3 ? '-'+ scope.row.integral : '+' + scope.row.integral}}
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange($event, 1)"
          @current-change="handleCurrentChange($event, 4)"
          :current-page="integralParameter.page"
          :page-size="integralParameter.rows"
          :total="integralParameter.total_number"
        ></el-pagination>
      </div>
    </el-dialog>
    <el-dialog title="余额明细" :visible.sync="showBalance" width="35%" center>
      <el-table :data="balance" height="400px" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="create_time" label="时间" align="center">
          <template slot-scope="scope">
            {{ getDateformat(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="用户行为" align="center">
          <template slot-scope="scope">
            {{ scope.row.type == 1 ? '后台赠送' : scope.row.type == 2 ? '开卡赠送' : scope.row.type == 3 ? '充值' : '支付扣余额' }}
          </template>
        </el-table-column>
        <el-table-column prop="num" label="金额" align="center"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @current-change="handleCurrentChange($event, 2)" :current-page="balanceParameter.page" :total="balanceParameter.total_number"></el-pagination>
      </div>
    </el-dialog>
    <el-dialog title="成长值明细" :visible.sync="showGrowLog" width="35%" center>
      <el-table :data="growLog" height="400px" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="create_time" label="时间" align="center">
          <template slot-scope="scope">
            {{ getDateformat(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="用户行为" align="center">
          <template slot-scope="scope">
            {{ scope.row.type == 1 ? '后台赠送' : scope.row.type == 2 ? '开卡赠送' : scope.row.type == 3 ? '充值' : '支付扣余额' }}
          </template>
        </el-table-column>
        <el-table-column prop="num" label="成长值" align="center"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @current-change="handleCurrentChange($event, 3)" :current-page="growLogParameter.page" :total="growLogParameter.total_number"></el-pagination>
      </div>
    </el-dialog>
    <!-- <el-dialog title="编辑信息" :visible.sync="showEditInfo" width="40%" center>
      <el-form label-width="100px">
        <el-form-item label="用户昵称：">{{ info.nickname }}</el-form-item>
        <el-form-item label="用户姓名：">
          <el-input v-model="editInfoForm.yonghuxingming"></el-input>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input v-model="editInfoForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="会员生日：">
          <div class="shenri">
            <el-input></el-input>
            <span>年</span>
            <el-input></el-input>
            <span>月</span>
            <el-input></el-input>
            <span>日</span>
          </div>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="editInfoForm.yonghuxingbie" :rows="8" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditInfo = !1">取 消</el-button>
        <el-button type="primary" @click="comefromEditInfo">确 定</el-button>
      </span>
    </el-dialog> -->
    <Rechange
      :is_detail="1"
      :showRecharge="showRecharge"
      :id="id"
      :log="log"
      :rechange_num="rechange_num"
      :balance="info.shopCard ? info.shopCard.balance : ''"
      @closePopup="closePopup"
      @getList="getList"
    ></Rechange>
    <SendCoupon :showCoupon="showCoupon" :is_detail="1" :id="id" :coupon_id="coupon_id" :coupon_num="coupon_num" @closePopup="closePopup"></SendCoupon>
  </el-main>
</template>

<script>
import { getDateformat } from '../../util/getDate';
import SendCoupon from './components/SendCoupon';
import Rechange from './components/Rechange';
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
import fash from '@/assets/image/fash.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
export default {
  components: {
    SendCoupon,
    Rechange,
  },
  data() {
    return {
      id: '',
      info: '',
      showMyCoupon: !1,
      showBalance: !1,
      showGrowLog: !1,
      showRecharge: !1,
      showCoupon: !1,
      showEditInfo: !0,
      showIntegral:!1,
      myCoupons: [],
      balance: [],
      integral:[],
      growLog: [],
      log: '',
      rechange_num: '',
      coupon_id: '',
      editInfoForm: {
        mobile: '',
        yonghuxingming: '',
        yonghuxingbie: '',
      },
      myCouponsParameter: {
        page: 1,
        rows: 10,
        total_number: 0,
      },
      balanceParameter: {
        page: 1,
        rows: 10,
        total_number: 0,
      },
      growLogParameter: {
        page: 1,
        rows: 10,
        total_number: 0,
      },
      integralParameter:{
        page:1,
        rows:10,
        total_number:0
      },
      group_color: [
        {
          color: '#CF984E',
          back: '#FFF6EB',
        },
        {
          color: '#409EFF',
          back: '#EBF4FF',
        },
        {
          color: '#FE6DA9',
          back: '#FEEAF2',
        },
        {
          color: '#34A853',
          back: '#E1F5E6',
        },
        {
          color: '#5654F3',
          back: '#EBEBFF',
        },
        {
          color: '#0BCED9',
          back: '#E1F4F5',
        },
      ],
      statistics: [],
      consumption: [],
      preference: [],
      act_reward: [],
      cluster: [],
    };
  },
  watch: {
    showMyCoupon(val) {
      if (val && !this.myCoupons.length) this.getCoupons();
    },
    showBalance(val) {
      if (val && !this.balance.length) this.getBalance();
    },
    showGrowLog(val) {
      if (val && !this.growLog.length) this.getGrowLog();
    },
    showIntegral(val) {
      if (val && !this.integral.length) this.getIntegral();
    },
  },
  created() {
    let utils = this.$store.state.utils;
    utils.page = Number(this.$route.query.page);
    utils.rows = Number(this.$route.query.rows);
    utils.is_record = !0;
    this.id = this.$route.query.id;
    this.getUserInfo();
  },
  methods: {
    //获取积分
    getIntegral(){
      this.$axios.post(this.$api.user.integralLog,{
        user_id:this.id,
        page:this.integralParameter.page,
        rows:this.integralParameter.rows
      }).then(res=>{
        if(res.code == 0){
          this.integral = res.result.list;
          this.integralParameter.total_number = res.result.total_number;
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    getUserInfo() {
      this.$axios
        .post(this.$api.user.userDetail, {
          id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(JSON.parse(JSON.stringify(res.result)));
            let result = res.result;
            this.statistics = [
              {
                order_amount: result.order_amount,
                order_num: result.order_num,
                integral: result.shopCard?.integral ?? '--',
                balance: result.shopCard?.balance ?? '--',
                grow_value: result.shopCard?.grow_value ?? '--',
                coupons_total: result.coupons_total,
              },
            ];
            this.consumption = [
              {
                xf_time: result.xf.xf_time,
                cost_price: result.xf.cost_price == 'NaN' ? '--' : result.xf.cost_price,
                xf_num: result.xf.xf_num,
                refund_amount: result.xf.refund_amount,
                refund_num: result.xf.refund_num,
              },
            ];
            this.preference = [
              {
                deep: result.preference.deep || '--',
                goods_classify: result.preference.goods_classify || '--',
                lastgoods: result.preference.lastgoods || '--',
                last_add_cart_goods: result.preference.last_add_cart_goods || '--',
                last_order_submit: result.preference.last_order_submit || '--',
              },
            ];
            if (result.cluster) {
              for (let i in result.cluster) {
                let index = Math.floor(Math.random() * 5);
                let color_style = this.group_color[index];
                result.cluster[i].back = color_style.back;
                result.cluster[i].color = color_style.color;
              }
              this.cluster = result.cluster;
            }
            if (result.card_set) {
              this.act_reward = [];
              if (result.card_set.act_reward.all_goods_by) {
                this.act_reward.push('全部商品包邮');
              }
              if (result.card_set.act_reward.goods_idscount) {
                this.act_reward.push(`会员享${result.card_set.act_reward.goods_idscount_num}折`);
              }
              if (Number(result.card_set.act_reward.is_coupons)) {
                this.act_reward.push(`赠送${result.card_set.act_reward.coupons_data.length}张优惠券`);
              }
              if (result.card_set.act_reward.is_money) {
                this.act_reward.push(`赠送${result.card_set.act_reward.money}元余额`);
              }
            }
            result.comefrom = result.comefrom == 1 ? wx : result.comefrom == 2 ? ali : result.comefrom == 3 ? fash : result.comefrom == 4 ? douyin : result.comefrom == 5 ? qq : baidu;
            this.info = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //编辑信息
    comefromEditInfo() {
      this.$axios
        .post(this.$api.member.editUser, {
          id: this.id,
          ...this.editInfoForm,
        })
        .then(res => {
          if (res.code == 0) {
            console.log(res);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange(val, type) {
      if (type == 1) {
        this.myCouponsParameter.page = val;
        this.getCoupons();
      } else if (type == 2) {
        this.balanceParameter.page = val;
        this.getBalance();
      } else if (type == 3) {
        this.growLogParameter.page = val;
        this.getGrowLog();
      }else if(type == 4){
        this.integralParameter.page = val;
        this.getIntegral();
      }
    },
    frozen() {
      this.$confirm(`${this.info.isblack ? '请确认是否恢复此用户，恢复后用户可以正常下单？' : '请确认是否冻结此用户，冻结后，此用户无法下单？'}`, `${this.info.isblack ? '恢复' : '冻结'}`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.user.setblack, {
            black: this.info.isblack ? 0 : 1,
            id: this.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.info.isblack = this.info.isblack ? 0 : 1;
              this.$message({
                message: `${this.info.isblack ? '冻结成功' : ' 恢复成功'}`,
                type: 'success',
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    getCoupons() {
      this.$axios
        .post(this.$api.user.myCoupons, {
          id: this.id,
          page: this.myCouponsParameter.page,
          rows: this.myCouponsParameter.rows,
        })
        .then(res => {
          if (res.code == 200) {
            this.myCoupons = res.result.list;
            this.myCouponsParameter.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getBalance() {
      this.$axios
        .post(this.$api.user.balance, {
          id: this.id,
          page: this.balanceParameter.page,
          rows: this.balanceParameter.rows,
        })
        .then(res => {
          if (res.code == 200) {
            this.balance = res.result.list;
            this.balanceParameter.total_number = res.result.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGrowLog() {
      this.$axios
        .post(this.$api.user.growLog, {
          id: this.id,
          page: this.growLogParameter.page,
          rows: this.growLogParameter.rows,
        })
        .then(res => {
          if (res.code == 200) {
            this.growLog = res.result.list;
            this.growLogParameter.total_number = res.result.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    closePopup() {
      this.showCoupon = !1;
      this.showRecharge = !1;
    },
    getList() {},
    getDateformat(val) {
      return getDateformat(val);
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  .shenri {
    display: flex;
    span {
      margin: 0 5px;
    }
  }
  .title {
    line-height: 45px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header {
    display: flex;
    align-items: center;
  }
  .padding {
    padding: 30px 0px 30px 0px;
  }
  .between {
    justify-content: space-between;
    padding: 20px 0px 20px 0px;
    .left {
      display: flex;
    }
  }
  .user-info {
    .content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        height: 50px;
        line-height: 50px;
        margin-right: 64px;
      }
      p:nth-child(1) {
        display: flex;
        align-items: center;
        .el-image {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
        img {
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }
      }
      P:nth-child(6),
      p:nth-child(7) {
        margin: 0;
      }
    }
  }
  .card-info {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      padding-left: 24px;
      background: #f8f9fb;
      p {
        margin-right: 64px;
      }
    }
  }
  .group {
    .content {
      padding: 20px 24px 10px 24px;
      background: #f8f9fb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          border-radius: 6px;
          padding: 0 20px;
          line-height: 36px;
          border: 1px solid;
          margin-right: 30px;
          margin-bottom: 10px;
        }
      }
      .el-button {
        margin-bottom: 10px;
      }
    }
  }
  .data {
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .content {
      display: flex;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 20px;
        width: 290px;
        height: 105px;
        margin-right: 20px;
        border: 1px solid #edeef0;
        .left {
          p:nth-child(1) {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          p:nth-child(2) {
            color: #979899;
          }
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .address {
    margin-top: 10px;
  }
  /deep/ .el-dialog__header {
    text-align: left;
  }
  /deep/ .el-dialog__footer {
    text-align: right;
  }
}
</style>
